// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-destinations-js": () => import("./../../../src/pages/destinations.js" /* webpackChunkName: "component---src-pages-destinations-js" */),
  "component---src-pages-gifting-checkout-js": () => import("./../../../src/pages/gifting/checkout.js" /* webpackChunkName: "component---src-pages-gifting-checkout-js" */),
  "component---src-pages-gifting-js": () => import("./../../../src/pages/gifting.js" /* webpackChunkName: "component---src-pages-gifting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journal-js": () => import("./../../../src/pages/journal.js" /* webpackChunkName: "component---src-pages-journal-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-students-js": () => import("./../../../src/pages/students.js" /* webpackChunkName: "component---src-pages-students-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */)
}

